import React from "react";
import "./Config.scss"
import TopBar from "../../components/Topbar";
import Mkt from "../../components/icons/MKT_1_ASS.svg"
import { Link } from "react-router-dom";

export default function ConfigPage() {
    return (<>
        <TopBar /><div className="Config">
            <div className="luxurious-roman-regular logo">
                <img src={Mkt} />
                Config 
                <Link to={"/config/users"}>Users</Link>
            </div>
        </div>

    </>)
}