import React from "react"
import "./TextField.scss"

export default function TextField(props) {


    return (<div className="TextField">
        <span>{props.label}</span>
        <input {...props} />
    </div>)
}