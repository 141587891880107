import React from "react";
import "./SideMenu.scss"
import { Icon } from "@iconify/react/dist/iconify.js";
import config from "./config.json"
import { Link } from "react-router-dom";

export default function SideMenu({ handleClose, sidemenuOpen, boxOpen }) {


    return (<>
        <div className={`SideMenuFocus ${sidemenuOpen ? "active" : ""}`}>
            <div className={`backgroundFocus ${boxOpen ? "active" : ""}`} onClick={handleClose}> </div>
            <div className={`boxMenu ${boxOpen ? "active" : ""}`} >
                <div className="headerSideMenu">
                    <div className="MenuBurger" onClick={handleClose}>
                        <Icon color={`#fff`} icon={`material-symbols:menu`} width={"1.2vw"} />
                    </div>
                    <div className="MenuClose" onClick={handleClose}>
                        <Icon color={`#fff`} icon={`material-symbols:close`} width={"1.2vw"} />
                    </div>
                </div>
                <div className="bodySideMenu">
                    {
                        Object.keys(config).map(key => {

                            console.log(config[key].title, config[key].items)

                            return (
                                <div className="MenuSection">
                                    {config[key].title != '' ? <span>{config[key].title} </span> : <></>}
                                    <div className="MenuList">
                                        {config[key].items.map(menuItem => (
                                            <Link className="MenuItem" to={`${menuItem.link ? menuItem.link : ""}`}>
                                                {menuItem.icon != "" ? <Icon icon={menuItem.icon} width={"1.3vw"}></Icon> : <></>}
                                                {menuItem.label}
                                            </Link>
                                        ))}
                                    </div>


                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </>)
}