import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from './useAlert';
import { jwtDecode } from "jwt-decode";
import api from '../service/api';

export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {

    const navigate = useNavigate()
    const { handleAlert
        //, isVisible, isTitle, initDescription, initType 
    } = useAlert()
    const [user, setUser] = useState({})
    const [initials, setInitials] = useState("")
    const [token, setToken] = useState()

    function getInitials(completeName) {
        if (typeof completeName != 'undefined') {
            const palavras = completeName.trim().split(' ');
            let initials = '';
            if (palavras.length > 1) {


                for (let i = 0; i < Math.min(2, palavras.length); i++) {
                    const palavra = palavras[i];
                    initials += palavra.charAt(0);
                }

                return initials.toUpperCase();
            } else {
                initials = `${completeName[0]}${completeName[1]}`
                return initials.toUpperCase();
            }

        }
    }
    const logout = () => {
        if (window.localStorage.getItem("auth_token")) {
            window.localStorage.removeItem("auth_token")
            navigate("/login")
        }
    }

    const postLoginData = async (objLogin) => {
        try {
            await api.post("/api/authentication/login", objLogin).then((r) => {
                console.log(objLogin)
                if (r.status === 201) {
                    console.log(r.data)
                    window.localStorage.setItem("auth_token", r.data['token'])
                    setToken(r.data['token'])
                    setUser(r.data.user)
                    setInitials(getInitials(r.data.user.name))
                    console.log("Sucesso", "Usuário logado com êxito.", "success")
                    // handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
                    navigate("/dashboard")
                } else {
                    console.log(r)
                    console.log("Erro", "Ocorreu um erro inesperado", "danger")
                    // handleAlert("Erro", "Ocorreu um erro inesperado", "danger")
                }
            })
        } catch (err) {
            console.log("Erro", err, "danger")
            //handleAlert("Erro", err, "danger")
        }
    }

    const checkIfIsLogged = () => {
        const tokenAux = window.localStorage.getItem("auth_token")
        if (tokenAux) {
            setToken(tokenAux)
            const decoded = jwtDecode(token);
            console.log(decoded)
            setUser(decoded)
            setInitials(getInitials(decoded.name))
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        const tokenAux = window.localStorage.getItem("auth_token")
        if (tokenAux) {
            let decoded = jwtDecode(tokenAux)
            setUser(decoded)
            setToken(tokenAux)
            setInitials(getInitials(decoded.name))
        }
    }, [])



    return <AuthContext.Provider value={{ postLoginData, user, initials, logout, checkIfIsLogged, token }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}



