import './App.scss';
import RouterComp from './router/routes';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css'; // Importe o tema "nova" do PrimeReact


function App() {
  return (
    <PrimeReactProvider>
      <RouterComp />
    </PrimeReactProvider>
  );
}

export default App;
