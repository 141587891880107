import React from "react";
import "./DashboardUsers.scss"
import TopBar from "../../../components/Topbar";
import Mkt from "../../../components/icons/MKT_1_ASS.svg"

export default function DashboardUsersPage() {
    return (<>
        <TopBar /><div className="DashboardUsers">
            <div className="luxurious-roman-regular logo">
                <img src={Mkt} />
                Dashboard Users
            </div>
        </div>

    </>)
}