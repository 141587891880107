import React, { useEffect } from "react";
import "./ConfirmModal.scss"

export default function ConfirmModal({ show, onConfirm, onDeny, onUpdate, title, message, handleClose}) {


    return (<div className={`modalDelete ${show ? "active" : ""}`}>
        <div className="modalDeleteBg" onClick={handleClose}></div>
        <div className="modalDeleteBox">
            <span>{title ? title : "Título"}</span>
            <p>{message ? message : "Você tem certeza?"}</p>

            <div className="modalDeleteActions">
                <button onClick={() => {
                    onConfirm();
                    handleClose();
                    onUpdate();
                }}>Sim</button>
                <button onClick={onDeny}>Não</button>
            </div>
        </div>
    </div>)
}