import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import "./EditModal.scss"

export default function EditModal({ show, handleEditData, editData, onConfirm, onDeny, onUpdate, title, message, handleClose, first,setFirst }) {

    const levels = [
        { label: "Colaborador", value: 0 },
        { label: "Admin", value: 1 },
        { label: "Desenvolvedor", value: 2 },
    ];

    const [selectedLevel, setSelectedLevel] = useState(null);


    useEffect(() => {
        if (editData && editData.level !== undefined && first) {
            setSelectedLevel(editData.level);
            setFirst(false)
        }
    }, [editData]);

    const handleSelectLevel = (e) => {
        setSelectedLevel(e.value)
        handleEditData("level", e.value)
    }

    return (
        <div className={`modalEdit ${show ? "active" : ""}`}>
            <div className="modalEditBg" onClick={handleClose}></div>
            <div className="modalEditBox">
                <span className="titulo">{title ? title : "Título"}</span>
                <p className="mensagem">{message ? message : "Você tem certeza?"}</p>

                <InputText
                    value={editData["name"]}
                    placeholder="Insira o nome do seu usuário"
                    onChange={(e) => {
                        handleEditData("name", e.target.value)
                    }} />
                <InputText
                    value={editData["email"]}
                    placeholder="Insira um email válido"
                    type="email"
                    onChange={(e) => {
                        handleEditData("email", e.target.value)
                    }} />

                <Dropdown
                    value={selectedLevel}
                    onChange={handleSelectLevel}
                    options={levels}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione o nível da conta"
                    className=""
                    checkmark={true}
                    highlightOnSelect={false}
                />

                <div className="modalEditActions">
                    <button onClick={() => {
                        onConfirm();
                        handleClose();
                        onUpdate();
                    }}>Salvar</button>
                    <button onClick={onDeny}>
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    )
}
