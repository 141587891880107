import axios from 'axios';


const apiUrl =  "https://apihub.mktnow.com.br"

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        // "Authorization": "63zne5T67yONhHqFEq7X"
    }
});

export default api;
