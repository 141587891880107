import React from "react";
import "./Plugins.scss"
import TopBar from "../../../components/Topbar";
import Mkt from "../../../components/icons/MKT_1_ASS.svg"

export default function PluginsPage() {
    return (<>
        <TopBar /><div className="Plugins">
            <div className="luxurious-roman-regular logo">
                <img src={Mkt} alt="" /> 
                Plugins
            </div>
        </div>

    </>)
}