import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AlertProvider } from '../context/useAlert';
import { AuthProvider } from '../context/useAuth';


//Authentication Pages
import LoginPage from '../pages/login';
import GenCodePage from '../pages/generate_code';
import ChangePassPage from '../pages/change_password';

//Dashboards
import DashboardPage from '../pages/dashboard';
import DashboardUsersPage from '../pages/dashboard/users';

//Modules
import ModulesPage from '../pages/modules';
import ModulesPluginsPage from '../pages/modules/plugins';

//My Account
import MyAccountPage from '../pages/account';

//Config
import ConfigPage from '../pages/config';
import ConfigUsersPage from '../pages/config/users';


function RouterComp() {


    return (
        <Router>
            <AlertProvider>
                <AuthProvider>
                    <Routes className="roboto-regular">
                        {/* START ROTAS NÃO AUTENTICADAS */}
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/gerar-codigo" element={<GenCodePage />} />
                        <Route exact path="/mudar-senha" element={<ChangePassPage />} />

                        <Route exact path='/' element={<PrivateRoute />}>
                            <Route path="/" exact element={<Navigate to={"/dashboard"}></Navigate>} />

                            {/* Dashboards */}
                            <Route path="/dashboard" exact element={<DashboardPage />} />
                            <Route path="/dashboard/users" exact element={<DashboardUsersPage />} />

                            {/* Modules */}
                            <Route path="/modules" exact element={<ModulesPage />} />
                            <Route path="/modules/plugins" exact element={<ModulesPluginsPage />} />

                            {/* My Account */}
                            <Route path="/my-account" exact element={<MyAccountPage />} /> 

                            {/* Config */}
                            <Route path="/config" exact element={<ConfigPage />} />
                            <Route path="/config/users" exact element={<ConfigUsersPage />} />


                        </Route>

                        {/* START NOT FOUND*/}
                        <Route path="/*" element={<h1>404</h1>} />
                        {/* END NOT FOUND*/}
                    </Routes>
                </AuthProvider>
            </AlertProvider>
        </Router>
    );
}

export default RouterComp;