import React, { useEffect, useState } from "react";
import "./Avatar.scss"
import { useAuth } from "../../../context/useAuth";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

export default function Avatar() {

    const { initials, logout, user } = useAuth()
    const navigate = useNavigate()


    const [showPopup, setShowPopup] = useState(false)

    const openPopup = () => {
        setShowPopup(true)
    }
    const closePopup = () => {
        setShowPopup(false)
    }

    return (<>
        <div className="Avatar" onClick={openPopup}>
            <div className="IconUser">
                {initials}
            </div>
        </div>
        <div onClick={closePopup} className={`popup-user-bg ${showPopup ? "active" : ""}`}></div>
        <div className={`popup-user ${showPopup ? "active" : ""}`}>

            <div className="info-container">
                <div className="Avatar">
                    <div className="IconUser">
                        {initials}
                    </div>
                </div>
                <div>
                    <div className="name">
                        {user?.name}
                    </div>
                    <div className="email">
                        {user?.email}
                    </div>
                </div>
            </div>
            <div className="info-actions">
                <ul>
                    <li onClick={() => { navigate("/my-account") }}>
                        <Icon width={24} icon={"material-symbols-light:account-box-outline"}></Icon>
                        <span>Minha conta</span>
                    </li>
                    <li onClick={logout}>
                        <Icon width={24} icon={"material-symbols-light:logout-sharp"}></Icon>
                        <span>Sair</span>
                    </li>
                </ul>
            </div>
        </div>
    </>)
}