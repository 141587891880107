import React, { useState } from "react";
import "./TopBar.scss"
import { Icon } from "@iconify/react/dist/iconify.js";
import Avatar from "./Avatar";
import SideMenu from "./SideMenu";

export default function TopBar({ }) {

    const [sidemenuOpen, setSidemenuOpen] = useState(false)
    const [boxOpen, setBoxOpen] = useState(false)
    const handleOpen = () => {
        setSidemenuOpen(true)
        setBoxOpen(true)
    }
    const handleClose = () => {

        setBoxOpen(false)

        setTimeout(() => {
            setSidemenuOpen(false)
        }, 200)
    }

    return (<>
        <div className="TopBarFocus">
            <div className="MenuBurger" onClick={handleOpen}>
                <Icon color={`#fff`} icon={`material-symbols:menu`} width={"1.2vw"} />
            </div>
            <div className="AvatarArea">
                <Avatar />
            </div>
        </div>
        <SideMenu sidemenuOpen={sidemenuOpen} boxOpen={boxOpen} handleClose={handleClose} />
    </>)
}