import React from "react";
import "./Modules.scss"
import TopBar from "../../components/Topbar";
import Mkt from "../../components/icons/MKT_1_ASS.svg"

export default function ModulesPage() {
    return (<>
        <TopBar /><div className="Modules">
            <div className="luxurious-roman-regular logo">
                <img src={Mkt} alt="" /> 
                Modules
            </div>
        </div>

    </>)
}