import React, { useState, useEffect } from "react";
import { Icon } from '@iconify/react';

function Alerts({title, description, animation, type}) {
    const [isVisible, setIsVisible] = useState(true);
    const [isAnimation, setIsAnimation] = useState(false);

    const close = () => {
        setIsAnimation(false);
        setTimeout(() => {
            setIsVisible(false);
        }, 200);
    }

    useEffect(()=>{
        setTimeout(() => {
            setIsAnimation(true)
        }, 200);
        setTimeout(() => {
            setIsAnimation(false);
            setTimeout(() => {
                setIsVisible(false);
            }, 200);
        }, 8000);
    },[])

  return (
    <>
        {isVisible &&
        <div className={`Alerts ${type} ${isAnimation}`}>
            <i className="icon"><Icon icon="pepicons-pop:exclamation" /></i>
            <h5>{title} <span>{description}</span></h5>
            <i className='close' onClick={close}><Icon icon="ep:close-bold" /></i>
        </div>
        }
    </>
  );
}
export default Alerts;
