import React, {   useState } from "react";
import "./LoginPage.scss"
import api from "../../service/api";
import TextField from "../../components/TextField";
import { useAlert } from "../../context/useAlert";
import Alerts from "../../components/Alerts";
import { Link, useNavigate } from "react-router-dom";

export default function ChangePassPage() {

    const { isVisible, isTitle, initDescription, initType } = useAlert()
    const navigate = useNavigate()

    const [objLogin, setObjLogin] = useState({})

    const handleLogin = (value, field) => {
        console.log(field, value)
        setObjLogin({
            ...objLogin,
            [field]: value
        })
    }

    const postData = async () => {

        let params = new URLSearchParams(window.location.search)
        
        objLogin["token"] = params.get("token")
        objLogin["email"] = params.get("email")

        console.log(objLogin)

        await api.post("/api/authentication/reset-pass", objLogin).then((r) => {
            if (r.status === 200) {
                console.log(r.data)
                console.log(r)
                navigate("/login")
            } else {
                console.log(r)
            }
        })
    }

    // useEffect(()=>{
    //     handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
    // },[])

    return (<>

        {isVisible &&
            <Alerts title={isTitle} description={initDescription} type={initType} />
        }

        <div className="containerLogin">
            <div className="box">
                <TextField
                    type={"password"}
                    label={"Senha"}
                    value={objLogin["password"]}
                    onChange={(e) => { handleLogin(e.target.value, "password") }}
                ></TextField>
                <TextField
                    type={"password"}
                    label={"Confirmar Senha"}
                    value={objLogin["confirmPassword"]}
                    onChange={(e) => { handleLogin(e.target.value, "confirmPassword") }}
                ></TextField>

                <button onClick={postData}>Alterar senha</button>

                <Link to={"/login"} >Voltar</Link>
            </div>
        </div>


    </>)
}