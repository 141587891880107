import React from "react";
import "./MyAccount.scss"
import TopBar from "../../components/Topbar";
import Mkt from "../../components/icons/MKT_1_ASS.svg"
import { useAuth } from "../../context/useAuth";

export default function MyAccountPage() {

    const { user } = useAuth()

    return (<>
        <TopBar /><div className="MyAccount">
            <div className="luxurious-roman-regular logo">
                <img src={Mkt} />
                Minha Conta

                <h2>{user?.name}</h2>
                <h3>{user?.email}</h3>
            </div>
        </div>

    </>)
}