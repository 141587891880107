import { createContext, useContext, useEffect, useState } from 'react';
 
export const AlertContext = createContext();


export const AlertProvider = ({ children }) => {
 
    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    return <AlertContext.Provider value={{ handleAlert, isVisible, isTitle, initDescription, initType }}>{children}</AlertContext.Provider>
}

export const useAlert = () => {
    return useContext(AlertContext)
}



