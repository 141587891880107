import React from "react";
import "./Dashboard.scss"
import TopBar from "../../components/Topbar";
import Mkt from "../../components/icons/MKT_1_ASS.svg"

export default function DashboardPage() {
    return (<>
        <TopBar /><div className="Dashboard">
            <div className="  logo"> 
                <img src={Mkt}/>
                <h1>Home Hub</h1>
            </div>
        </div>

    </>)
}