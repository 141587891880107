import React, { useEffect, useState } from "react";
import "./ConfigUsers.scss"
import TopBar from "../../../components/Topbar";
import Mkt from "../../../components/icons/MKT_1_ASS.svg"
import TableConfigUser from "../../../components/Tables/Config/Users";
import { Icon } from "@iconify/react/dist/iconify.js";
import api from "../../../service/api";
import { useAuth } from "../../../context/useAuth";
import ConfirmModal from "../../../components/ConfirmModal";
import EditModal from "../../../components/Modals/Config/Users/EditModal";
import NewModal from "../../../components/Modals/Config/Users/NewModal";

export default function ConfigUsersPage() {

    const { token, logout } = useAuth()
    const [userList, setUserList] = useState([])

    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showNew, setShowNew] = useState(false)
    const [selectId, setSelectId] = useState(null)
    const [first, setFirst] = useState(true)
    const [editData, setEditData] = useState({})
    const [newData, setNewData] = useState({})

    // useEffect(() => {
    //     console.log(editData)
    // }, [editData])

    const handleEditData = (field, value) => {
        setEditData({
            ...editData,
            [field]: value
        })
    }

    const handleNewData = (field, value) => {
        setNewData({
            ...newData,
            [field]: value
        })
    }

    const handleDelete = async () => {
        try {
            //if (selectId) {
            await api.delete(`/api/users/${selectId}`, {
                headers: {
                    "Authorization": token
                }
            }).then(r => {
                if (r.status == 200) {
                    console.log(r.data)
                }
            })
            //}
        } catch (err) {
            if (err.response.status == 401) {
                logout()
            }
        }
    }

    const handleEdit = async () => {
        try {
            console.log("edit data confirm", selectId, editData)
            //if (selectId) {
            await api.put(`/api/users/${selectId}`, editData, {
                headers: {
                    "Authorization": token
                }
            }).then(r => {
                if (r.status == 200) {
                    console.log(r.data)
                    getUsers()
                }
            })
            //}
        } catch (err) {
            if (err.response.status == 401) {
                logout()
            }
        }
    }
    const handleNew = async () => {
        try {
            //if (selectId) {
            await api.post(`/api/users?generate=true`, newData, {
                headers: {
                    "Authorization": token
                }
            }).then(r => {
                if (r.status == 200) {
                    console.log(r.data)
                    getUsers()
                }
            })
            //}
        } catch (err) {
            if (err.response.status == 401) {
                logout()
            }
        }
    }

    const getUsers = async () => {
        try {
            await api.get("/api/users", {
                headers: {
                    "Authorization": token
                }
            }).then(r => {
                if (r.status == 200) {
                    console.log(r.data)
                    setUserList(r.data)
                }
            })
        } catch (err) {
            if (err.response.status == 401) {
                logout()
            }
        }

    }

    const handleOpenDelete = (id) => {
        setSelectId(id)
        setShowConfirm(true)
    }
    const handleCloseDelete = (id) => {
        setSelectId(null)
        setShowConfirm(false)
    }

    const handleOpenEdit = (item) => {
        console.log("Opening Edit", item)
        setSelectId(item?.id)
        setEditData(item)
        setShowEdit(true)
        setFirst(true)
    }
    const handleCloseEdit = () => {
        console.log("Closing Edit")
        setSelectId(null)
        setEditData({})
        setShowEdit(false)
    }

    const handleOpenNew = () => {
        console.log("Opening New")
        setShowNew(true)
    }
    const handleCloseNew = () => {
        console.log("Closing New")
        setShowNew(false)
    }

    useEffect(() => {
        if (token) {
            getUsers()
        }
    }, [token])

    return (<>
        <TopBar />
        <div className="ConfigUsers">
            <div className="roboto-regular boxdata-container content">
                <div className="titleConfig">
                    <div className="iconConfig">
                        <Icon width={50} icon={"mdi:users"}></Icon>
                    </div>
                    <div className="contentConfig">
                        <h1>Configurações dos usuários</h1>
                        <p>Esta página, é direcionada ao gerenciamento dos usuários do painel</p>
                    </div>
                </div>

                <TableConfigUser
                    userList={userList}
                    handleOpenDelete={handleOpenDelete}
                    handleOpenEdit={handleOpenEdit}
                    handleOpenNew={handleOpenNew}
                ></TableConfigUser>
            </div>
        </div>
        <ConfirmModal
            show={showConfirm}
            onConfirm={handleDelete}
            handleClose={handleCloseDelete}
            onDeny={handleCloseDelete}
            onUpdate={getUsers}
            title={"Remoção"}
            message={"Tem certeza que deseja remover este usuário?"}
        />
        <EditModal
            show={showEdit}
            editData={editData}
            handleEditData={handleEditData}
            onConfirm={handleEdit}
            handleClose={handleCloseEdit}
            onDeny={handleCloseEdit}
            onUpdate={getUsers}
            message={"Este popup permite o gerenciamento dos usuários"}
            title={"Área de Edição"}
            first={first}
            setFirst={setFirst}
        />

        <NewModal
            show={showNew}
            newData={newData}
            handleNewData={handleNewData}
            onConfirm={handleNew}
            handleClose={handleCloseNew}
            onDeny={handleCloseNew}
            onUpdate={getUsers}
            message={"Este popup permite a criação de um novo usuário"}
            title={"Área de criação"}
            first={first}
            setFirst={setFirst}
        />
    </>)
}