import React, { useEffect, useState } from "react";
import "./Users.scss"
import { Icon } from "@iconify/react/dist/iconify.js";
import { Ring } from "@uiball/loaders";
import { Tooltip } from 'primereact/tooltip';
import LevelBadge from "../../../LevelBadge";

export default function TableConfigUser({ userList, handleOpenDelete, handleOpenEdit, handleOpenNew }) {


    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (userList.length > 0) {
            setLoading(false)
        }
    }, [userList])

    return (<>
        <Tooltip className="tooltipButtons" target=".cardActions button" mouseTrack mouseTrackLeft={10} />
        <div className="tableUsers">

            <div className="headerFilter">
                <div className="searchContainer">
                    <input placeholder="Pesquisar" className="search"></input>
                    <div className="searchIcon">
                        <Icon color="#fff" width={25} icon={"ic:sharp-search"}></Icon>
                    </div>
                </div>
                <div>
                    <button className="buttonNovo" onClick={handleOpenNew }>
                        <strong>Novo</strong>
                        <Icon width={15} icon={"ic:baseline-plus"} />
                    </button>
                </div>
            </div>
            <div className="tableUsersContentContainer">
                <div className="tableUsersContent">
                    {loading ? <div className="loadingData">
                        <Ring color="#fff"></Ring>
                    </div>
                        :
                        userList?.map((item, index) => (
                            <>
                                <div className="cardUser" key={index}>
                                    <div style={{ width: "10%" }}>
                                        {item.id}
                                    </div>
                                    <div style={{ width: "40%" }}>
                                        {item.name}
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        {item.email}
                                    </div>
                                    <div style={{ width: "10%" }}>
                                        <LevelBadge level={item.level} />
                                    </div>
                                    <div className="cardActions" style={{ width: "10%" }}>
                                        <button
                                            data-pr-tooltip="Visualizar"
                                            data-pr-position="right"
                                            data-pr-at="right+5 top"
                                            data-pr-my="left center-2"
                                        >
                                            <Icon width={28} icon={"carbon:view"}></Icon>
                                        </button>
                                        <button
                                            data-pr-tooltip="Editar"
                                            data-pr-position="right"
                                            data-pr-at="right+5 top"
                                            data-pr-my="left center-1"
                                            onClick={() => { handleOpenEdit(item) }}>
                                            <Icon width={28} icon={"carbon:edit"}></Icon>
                                        </button>
                                        <button
                                            data-pr-tooltip="Deletar"
                                            data-pr-position="right"
                                            data-pr-at="right+5 top"
                                            data-pr-my="left center-2"
                                            onClick={() => { handleOpenDelete(item.id) }}>
                                            <Icon width={28} icon={"ep:delete"}></Icon>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>

        </div>
    </>)
}