import React, { useEffect, useState } from "react";
import "./LoginPage.scss"
import TextField from "../../components/TextField";
import { useAlert } from "../../context/useAlert";
import Alerts from "../../components/Alerts";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/useAuth";
import Mkt from "../../components/icons/MKT_1_ASS_white.svg"

export default function LoginPage() {

    const { isVisible, isTitle, initDescription, initType } = useAlert()
    const { postLoginData, checkIfIsLogged } = useAuth()

    useEffect(() => {
        checkIfIsLogged()
    }, [])

    const [objLogin, setObjLogin] = useState({})

    const handleLogin = (value, field) => {
        console.log(field, value)
        setObjLogin({
            ...objLogin,
            [field]: value
        })
    }


    return (<>

        {isVisible &&
            <Alerts title={isTitle} description={initDescription} type={initType} />
        }

        <div className="containerLogin">
            <div className="box">
                <img color="#fff" src={Mkt} />
                <TextField
                    type={"email"}
                    label={"Email"}
                    placeholder="Digite seu email"
                    value={objLogin["email"]}
                    onChange={(e) => { handleLogin(e.target.value, "email") }}
                ></TextField>
                <TextField
                    type={"password"}
                    label={"Senha"}
                    placeholder="Digite sua senha"
                    value={objLogin["password"]}
                    onChange={(e) => { handleLogin(e.target.value, "password") }}
                ></TextField>

                <button onClick={() => postLoginData(objLogin)}>Login</button>

                <Link to={"/gerar-codigo"} >Esqueceu a senha?</Link>
            </div>
        </div>


    </>)
}