import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./LevelBadge.scss"

export default function LevelBadge({ level }) {


    return (<>

        {level === 0 && <div className="level-badge">
            <div className="badge-tag">
                <Icon icon={"wpf:collaborator"} />
                <strong className="badge-title">Colaborador</strong>
            </div>
        </div>}

        {level === 1 && <div className="level-badge">
            <div className="badge-tag">
                <Icon icon={"eos-icons:admin"} />
                <strong className="badge-title">Administrador</strong>
            </div>
        </div>}

        {level === 2 && <div className="level-badge">
            <div className="badge-tag">
                <Icon icon={"iconoir:developer"} />
                <strong className="badge-title">Desenvolvedor</strong>
            </div>
        </div>}
    </>)
}