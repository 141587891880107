import React, { useState } from "react";
import "./LoginPage.scss"
import api from "../../service/api";
import TextField from "../../components/TextField";
import { useAlert } from "../../context/useAlert";
import Alerts from "../../components/Alerts";
import { Link } from "react-router-dom";

export default function GenCodePage() {

    const { handleAlert, isVisible, isTitle, initDescription, initType } = useAlert()

    const [objCode, setObjCode] = useState({
        origin: window.location.origin
    }) 

    const handleCode = (value, field) => {
        console.log(field, value)
        setObjCode({
            ...objCode,
            [field]: value
        })
    }

    const postData = async () => {
        await api.post("/api/authentication/forgot-pass", objCode).then((r) => {
            console.log(r.status)
            if (r.status === 200) {
                console.log(r.data)
                console.log(r)
                handleAlert("Sucesso", "O link de alteração da conta foi enviado para o email informado.", "success")
            } else {
                console.log(r)
            }
        })
    }

    // useEffect(()=>{
    //     handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
    // },[])

    return (<>

        {isVisible &&
            <Alerts title={isTitle} description={initDescription} type={initType} />
        }

        <div className="containerLogin">
            <div className="box">
                <TextField
                    type={"email"}
                    label={"Email"}
                    value={objCode["email"]}
                    onChange={(e) => { handleCode(e.target.value, "email") }}
                ></TextField> 

                <button onClick={postData}>Enviar Link</button>

                <Link to={"/login"} >Voltar</Link>
            </div>
        </div>


    </>)
}